<template>
  <button
    class="relative rounded-lg px-3 py-2.5 hover:bg-gray-200 hover:bg-opacity-50 group w-full flex items-center text-left gap-3"
    @click="$emit('open')"
  >
    <div class="flex-shrink-0 font-medium flex items-center gap-1">
      <fw-icon-arrow-right class="w-5 h-5 text-gray-500" />
    </div>

    <div class="flex-1 text-sm font-medium capitalize flex gap-3">
      <v-clamp autoresize :max-lines="1">
        {{ title }}
      </v-clamp>
    </div>
    <div v-if="false" class="w-32 flex flex-shrink-0">
      <fw-tag :type="'medium'" size="xs">
        {{ gender }}
      </fw-tag>
    </div>
    <div class="w-32 flex flex-shrink-0">
      <fw-tag size="xs">
        {{ sport.type }}
      </fw-tag>
    </div>

    <div class="w-52 text-xs text-gray-500 font-normal justify-end">
      <div>{{ $t('createdAt') }} {{ sport.created_at | formatDateTime }}</div>
    </div>
  </button>
</template>

<script>
// import { TOURNAMENT_STATUS_TAG_COLORS } from '@/utils/index.js'

export default {
  name: 'RecordSport',
  props: {
    sport: {
      type: Object,
    },
    modalities: {
      type: Object,
    },
  },

  data() {
    return {
      //colors: TOURNAMENT_STATUS_TAG_COLORS,
    }
  },

  computed: {
    language() {
      return this.$store.state.language || 'pt'
    },
    title() {
      return (
        (this.modalities[this.sport.modality] ? this.modalities[this.sport.modality].title : '') +
        (this.sport.category != null ? ' - ' + this.sport.category : '')
      )
    },
    gender() {
      return this.sport.gender == 'M' ? 'Masculino' : this.sport.gender == 'F' ? 'Feminino' : 'Misto'
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "createdAt": "Criado em",
    "status": {
      "inactive": "Inativo",
      "signup-open": "Inscrições abertas",
      "signup-closed": "Inscrições fechadas",
      "running": "Em curso",
      "finished": "Terminado",
      "canceled": "Cancelado",
      "closed": "Fechado"
    },
    "gender": {
      "U": "Misto",
      "M": "Masculino",
      "F": "Feminino"
    }
  },
  "en": {
    "createdAt": "Created at",
    "status": {
      "inactive": "Inactive",
      "signup-open": "Signup open",
      "signup-closed": "Signup closed",
      "running": "Running",
      "finished": "Finished",
      "canceled": "Canceled",
      "closed": "Closed"
    },
    "gender": {
      "U": "Mixed",
      "M": "Male",
      "F": "Female"
    }
  }
}
</i18n>
