<template>
  <button
    class="group bg-gray-200 flex-col flex px-4 py-3 rounded-xl gap-2 justify-around hover:bg-opacity-70"
    @click="$emit('view', edition.key)"
  >
    <div class="flex gap-2">
      <div>
        <v-clamp autoresize :max-lines="1" class="font-bold uppercase text-lg">{{ edition.name }}</v-clamp>
      </div>
      <div>
        <fw-tag v-if="edition.is_current" type="primary" size="xs">Atual</fw-tag>
      </div>
    </div>
    <div v-if="edition.start_at && edition.end_at" class="font-semibold text-gray-500 text-sm">
      {{ edition.start_at.date | formatDate }} a {{ edition.end_at.date | formatDate }}
    </div>
  </button>
</template>

<script>
export default {
  name: 'CardEdition',
  props: {
    edition: {
      type: Object,
    },
  },

  data() {
    return {}
  },

  computed: {
    language() {
      return this.$store.state.language || 'pt'
    },
  },
}
</script>

<i18n>
{
  "pt": {},
  "en": {}
}
</i18n>
